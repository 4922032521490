/*
 * Copyright 2021 Chaos Mesh Authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
import { Box, Typography } from '@mui/material'
import _ from 'lodash'

const RadioLabel = (label: string, uuid?: string) => (
  <Box display="flex" justifyContent="space-between" alignItems="center">
    <Typography>{label}</Typography>
    {uuid && (
      <Box ml={3}>
        <Typography variant="body2" color="textSecondary" title={uuid}>
          {_.truncate(uuid)}
        </Typography>
      </Box>
    )}
  </Box>
)

export default RadioLabel
