/*
 * Copyright 2022 Chaos Mesh Authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
import flat from 'flat'
import messages from 'i18n/messages'
import { useMemo } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'

import { useStoreSelector } from 'store'

const IntlProvider: React.FC = ({ children }) => {
  const { lang } = useStoreSelector((state) => state.settings)
  const intlMessages = useMemo<Record<string, string>>(() => flat(messages[lang]), [lang])

  return (
    <ReactIntlProvider messages={intlMessages} locale={lang} defaultLocale="en">
      {children}
    </ReactIntlProvider>
  )
}

export default IntlProvider
