var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import FormControl from '../FormControl';
import OutlinedInput from '../OutlinedInput';
export default function (_a) {
    var { label, helperText } = _a, rest = __rest(_a, ["label", "helperText"]);
    const { disabled, error, fullWidth } = rest;
    return (_jsx(FormControl, Object.assign({ disabled: disabled, error: error, label: label, helperText: helperText, fullWidth: fullWidth }, { children: _jsx(OutlinedInput, Object.assign({}, rest), void 0) }), void 0));
}
