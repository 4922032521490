import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * Copyright 2021 Chaos Mesh Authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
import { Box, Divider, Typography } from '@mui/material';
import Space from '../Space';
const PaperTop = ({ title, subtitle, h1, divider, boxProps, children }) => (_jsxs(Space, Object.assign({ width: "100%" }, { children: [_jsxs(Box, Object.assign({}, boxProps, { display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: [_jsxs(Box, Object.assign({ flex: 1 }, { children: [_jsx(Typography, Object.assign({ variant: h1 ? 'h5' : 'h6', component: h1 ? 'h1' : 'div', fontWeight: h1 ? 'bold' : undefined }, { children: title }), void 0), subtitle && (_jsx(Typography, Object.assign({ variant: "body2", color: "textSecondary" }, { children: subtitle }), void 0))] }), void 0), children] }), void 0), divider && _jsx(Divider, {}, void 0)] }), void 0));
export default PaperTop;
