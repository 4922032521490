var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * Copyright 2022 Chaos Mesh Authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
import { FormControl, FormHelperText, FormLabel } from '@mui/material';
export default function (_a) {
    var { label, LabelProps, helperText, children } = _a, rest = __rest(_a, ["label", "LabelProps", "helperText", "children"]);
    return (_jsxs(FormControl, Object.assign({}, rest, { children: [label && (_jsx(FormLabel, Object.assign({ disabled: rest.disabled, error: false, focused: false, sx: { mb: 1.5, color: 'text.primary', fontSize: 'body2.fontSize', fontWeight: 500 } }, LabelProps, { children: label }), void 0)), children, helperText && _jsx(FormHelperText, Object.assign({ component: "div" }, { children: helperText }), void 0)] }), void 0));
}
