import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * Copyright 2021 Chaos Mesh Authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, } from '@mui/material';
const ConfirmDialog = ({ open, close, title, description, cancelText = 'Cancel', confirmText = 'Confirm', onConfirm, children, dialogProps, }) => {
    const handleConfirm = () => {
        typeof onConfirm === 'function' && onConfirm();
        typeof close === 'function' && close();
    };
    return (_jsxs(Dialog, Object.assign({ open: open, onClose: close }, dialogProps, { children: [_jsx(DialogTitle, Object.assign({ sx: { p: 4 } }, { children: title }), void 0), (children || description) && (_jsx(DialogContent, Object.assign({ sx: { p: 4 } }, { children: description ? _jsx(DialogContentText, { children: description }, void 0) : children }), void 0)), onConfirm && (_jsxs(DialogActions, { children: [_jsx(Button, Object.assign({ color: "secondary", onClick: close }, { children: cancelText }), void 0), _jsx(Button, Object.assign({ autoFocus: true, onClick: handleConfirm }, { children: confirmText }), void 0)] }, void 0))] }), void 0));
};
export default ConfirmDialog;
