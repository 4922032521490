var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/*
 * Copyright 2022 Chaos Mesh Authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
import { OutlinedInput as MuiOutlinedInput } from '@mui/material';
import { forwardRef } from 'react';
const OutlinedInput = forwardRef((_a, ref) => {
    var { sx } = _a, rest = __rest(_a, ["sx"]);
    return (_jsx(MuiOutlinedInput, Object.assign({ size: "small", sx: Object.assign({ px: rest.startAdornment || rest.endAdornment ? 2 : 0, borderColor: 'divider', typography: 'body2', '&:hover': {
                bgcolor: 'action.hover',
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'divider',
                },
            }, '&.Mui-focused': {
                bgcolor: 'none',
                '.MuiOutlinedInput-notchedOutline': {
                    borderColor: 'primary.main',
                    boxShadow: (theme) => `0 0 0 2px ${theme.palette.secondaryContainer.main}`,
                },
            } }, sx) }, rest, { ref: ref }), void 0));
});
export default OutlinedInput;
