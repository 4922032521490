var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/*
 * Copyright 2022 Chaos Mesh Authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
import { Box, Chip, Select } from '@mui/material';
import FormControl from '../FormControl';
import OutlinedInput from '../OutlinedInput';
export default function SelectField(_a) {
    var { label, helperText, onRenderValueDelete } = _a, props = __rest(_a, ["label", "helperText", "onRenderValueDelete"]);
    const { disabled, error, fullWidth } = props;
    return (_jsx(FormControl, Object.assign({ disabled: disabled, error: error, label: label, helperText: helperText, fullWidth: fullWidth }, { children: _jsx(Select, Object.assign({}, props, { input: _jsx(OutlinedInput, {}, void 0), renderValue: props.multiple
                ? (selected) => (_jsx(Box, Object.assign({ display: "flex", flexWrap: "wrap", gap: 1 }, { children: selected.map((val) => (_jsx(Chip, { label: val, color: "primary", onDelete: onRenderValueDelete ? onRenderValueDelete(val) : undefined, onMouseDown: (e) => e.stopPropagation(), sx: { height: 24 } }, val))) }), void 0))
                : undefined }), void 0) }), void 0));
}
