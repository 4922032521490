var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * Copyright 2021 Chaos Mesh Authors.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 */
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu as MuiMenu, styled } from '@mui/material';
import { useState } from 'react';
const StyledMenu = styled((props) => _jsx(MuiMenu, Object.assign({ elevation: 0 }, props), void 0))(({ theme }) => ({
    '& .MuiPaper-root': {
        border: `1px solid ${theme.palette.divider}`,
    },
    '& .MuiMenu-list': {
        padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
        padding: '3px 8px',
    },
}));
const Menu = (_a) => {
    var { IconButtonProps, IconProps, children } = _a, rest = __rest(_a, ["IconButtonProps", "IconProps", "children"]);
    const [anchorEl, setAnchorEl] = useState(null);
    const onClick = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };
    const onClose = (e) => {
        e && e.stopPropagation(); // Allow no event.
        setAnchorEl(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, Object.assign({}, IconButtonProps, { onClick: onClick }, { children: _jsx(MoreVertIcon, Object.assign({}, IconProps), void 0) }), void 0), _jsx(StyledMenu, Object.assign({}, rest, { anchorEl: anchorEl, open: Boolean(anchorEl), onClose: onClose }, { children: typeof children === 'function' ? children({ onClose }) : children }), void 0)] }, void 0));
};
export default Menu;
