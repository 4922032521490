/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Chaos Mesh Dashboard API
 * Swagger for Chaos Mesh Dashboard. If you encounter any problems with API, please click on the issues link below to report.
 * OpenAPI spec version: 2.2
 */
import { useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { customInstance } from '../api/http'
import type {
  ConfigChaosDashboardConfig,
  CoreEvent,
  CoreWorkflowDetail,
  CoreWorkflowMeta,
  CurlRequestForm,
  DeleteArchivesParams,
  DeleteArchivesSchedulesParams,
  DeleteArchivesWorkflowsParams,
  DeleteExperimentsParams,
  DeleteExperimentsUidParams,
  DeleteSchedulesParams,
  DeleteTemplatesStatuschecksStatuscheckParams,
  GetArchivesParams,
  GetArchivesSchedulesParams,
  GetArchivesWorkflowsParams,
  GetCommonAnnotationsParams,
  GetCommonLabelsParams,
  GetCommonPhysicalmachineAnnotationsParams,
  GetCommonPhysicalmachineLabelsParams,
  GetCommonRbacConfig200,
  GetCommonRbacConfigParams,
  GetEventsParams,
  GetEventsWorkflowUidParams,
  GetExperimentsParams,
  GetExperimentsStateParams,
  GetSchedulesParams,
  GetTemplatesStatuschecksParams,
  GetTemplatesStatuschecksStatuscheckParams,
  GetWorkflowsParams,
  PostExperiments200,
  PostExperimentsBody,
  StatusAllChaosStatus,
  TypesArchive,
  TypesArchiveDetail,
  TypesExperiment,
  TypesExperimentDetail,
  TypesPhysicalMachine,
  TypesPod,
  TypesSchedule,
  TypesScheduleDetail,
  TypesStatusCheckTemplate,
  TypesStatusCheckTemplateBase,
  TypesStatusCheckTemplateDetail,
  UtilsAPIError,
  UtilsMapStringSliceResponse,
  UtilsResponse,
  V1alpha1PhysicalMachineSelectorSpec,
  V1alpha1PodSelectorSpec,
  V1alpha1Schedule,
  V1alpha1Template,
  V1alpha1TemplateBody,
  V1alpha1WorkflowBody,
} from './index.schemas'

/**
 * Delete the specified archived experiment.
 * @summary Delete the specified archived experiment.
 */
export const deleteArchives = (params: DeleteArchivesParams) => {
  return customInstance<UtilsResponse>({ url: `/archives`, method: 'delete', params })
}

export type DeleteArchivesMutationResult = NonNullable<Awaited<ReturnType<typeof deleteArchives>>>

export type DeleteArchivesMutationError = UtilsAPIError

export const useDeleteArchives = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteArchives>>,
    TError,
    { params: DeleteArchivesParams },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteArchives>>, { params: DeleteArchivesParams }> = (
    props
  ) => {
    const { params } = props ?? {}

    return deleteArchives(params)
  }

  return useMutation<Awaited<ReturnType<typeof deleteArchives>>, TError, { params: DeleteArchivesParams }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Get archived chaos experiments.
 * @summary Get archived chaos experiments.
 */
export const getArchives = (params?: GetArchivesParams, signal?: AbortSignal) => {
  return customInstance<TypesArchive[]>({ url: `/archives`, method: 'get', params, signal })
}

export const getGetArchivesQueryKey = (params?: GetArchivesParams) => [`/archives`, ...(params ? [params] : [])]

export type GetArchivesQueryResult = NonNullable<Awaited<ReturnType<typeof getArchives>>>
export type GetArchivesQueryError = UtilsAPIError

export const useGetArchives = <TData = Awaited<ReturnType<typeof getArchives>>, TError = UtilsAPIError>(
  params?: GetArchivesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getArchives>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetArchivesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getArchives>>> = ({ signal }) => getArchives(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getArchives>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Delete the specified archived experiment.
 * @summary Delete the specified archived experiment.
 */
export const deleteArchivesUid = (uid: string) => {
  return customInstance<UtilsResponse>({ url: `/archives/${uid}`, method: 'delete' })
}

export type DeleteArchivesUidMutationResult = NonNullable<Awaited<ReturnType<typeof deleteArchivesUid>>>

export type DeleteArchivesUidMutationError = UtilsAPIError

export const useDeleteArchivesUid = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteArchivesUid>>, TError, { uid: string }, TContext>
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteArchivesUid>>, { uid: string }> = (props) => {
    const { uid } = props ?? {}

    return deleteArchivesUid(uid)
  }

  return useMutation<Awaited<ReturnType<typeof deleteArchivesUid>>, TError, { uid: string }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Get the archived chaos experiment's detail by uid.
 * @summary Get an archived chaos experiment.
 */
export const getArchivesUid = (uid: string, signal?: AbortSignal) => {
  return customInstance<TypesArchiveDetail>({ url: `/archives/${uid}`, method: 'get', signal })
}

export const getGetArchivesUidQueryKey = (uid: string) => [`/archives/${uid}`]

export type GetArchivesUidQueryResult = NonNullable<Awaited<ReturnType<typeof getArchivesUid>>>
export type GetArchivesUidQueryError = UtilsAPIError

export const useGetArchivesUid = <TData = Awaited<ReturnType<typeof getArchivesUid>>, TError = UtilsAPIError>(
  uid: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getArchivesUid>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetArchivesUidQueryKey(uid)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getArchivesUid>>> = ({ signal }) => getArchivesUid(uid, signal)

  const query = useQuery<Awaited<ReturnType<typeof getArchivesUid>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!uid,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Delete the specified archived schedule.
 * @summary Delete the specified archived schedule.
 */
export const deleteArchivesSchedules = (params: DeleteArchivesSchedulesParams) => {
  return customInstance<UtilsResponse>({ url: `/archives/schedules`, method: 'delete', params })
}

export type DeleteArchivesSchedulesMutationResult = NonNullable<Awaited<ReturnType<typeof deleteArchivesSchedules>>>

export type DeleteArchivesSchedulesMutationError = UtilsAPIError

export const useDeleteArchivesSchedules = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteArchivesSchedules>>,
    TError,
    { params: DeleteArchivesSchedulesParams },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteArchivesSchedules>>,
    { params: DeleteArchivesSchedulesParams }
  > = (props) => {
    const { params } = props ?? {}

    return deleteArchivesSchedules(params)
  }

  return useMutation<
    Awaited<ReturnType<typeof deleteArchivesSchedules>>,
    TError,
    { params: DeleteArchivesSchedulesParams },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Get archived schedule experiments.
 * @summary Get archived schedule experiments.
 */
export const getArchivesSchedules = (params?: GetArchivesSchedulesParams, signal?: AbortSignal) => {
  return customInstance<TypesArchive[]>({ url: `/archives/schedules`, method: 'get', params, signal })
}

export const getGetArchivesSchedulesQueryKey = (params?: GetArchivesSchedulesParams) => [
  `/archives/schedules`,
  ...(params ? [params] : []),
]

export type GetArchivesSchedulesQueryResult = NonNullable<Awaited<ReturnType<typeof getArchivesSchedules>>>
export type GetArchivesSchedulesQueryError = UtilsAPIError

export const useGetArchivesSchedules = <
  TData = Awaited<ReturnType<typeof getArchivesSchedules>>,
  TError = UtilsAPIError
>(
  params?: GetArchivesSchedulesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getArchivesSchedules>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetArchivesSchedulesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getArchivesSchedules>>> = ({ signal }) =>
    getArchivesSchedules(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getArchivesSchedules>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Delete the specified archived schedule.
 * @summary Delete the specified archived schedule.
 */
export const deleteArchivesSchedulesUid = (uid: string) => {
  return customInstance<UtilsResponse>({ url: `/archives/schedules/${uid}`, method: 'delete' })
}

export type DeleteArchivesSchedulesUidMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteArchivesSchedulesUid>>
>

export type DeleteArchivesSchedulesUidMutationError = UtilsAPIError

export const useDeleteArchivesSchedulesUid = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteArchivesSchedulesUid>>,
    TError,
    { uid: string },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteArchivesSchedulesUid>>, { uid: string }> = (
    props
  ) => {
    const { uid } = props ?? {}

    return deleteArchivesSchedulesUid(uid)
  }

  return useMutation<Awaited<ReturnType<typeof deleteArchivesSchedulesUid>>, TError, { uid: string }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Get the detail of an archived schedule experiment.
 * @summary Get the detail of an archived schedule experiment.
 */
export const getArchivesSchedulesUid = (uid: string, signal?: AbortSignal) => {
  return customInstance<TypesArchiveDetail>({ url: `/archives/schedules/${uid}`, method: 'get', signal })
}

export const getGetArchivesSchedulesUidQueryKey = (uid: string) => [`/archives/schedules/${uid}`]

export type GetArchivesSchedulesUidQueryResult = NonNullable<Awaited<ReturnType<typeof getArchivesSchedulesUid>>>
export type GetArchivesSchedulesUidQueryError = UtilsAPIError

export const useGetArchivesSchedulesUid = <
  TData = Awaited<ReturnType<typeof getArchivesSchedulesUid>>,
  TError = UtilsAPIError
>(
  uid: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getArchivesSchedulesUid>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetArchivesSchedulesUidQueryKey(uid)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getArchivesSchedulesUid>>> = ({ signal }) =>
    getArchivesSchedulesUid(uid, signal)

  const query = useQuery<Awaited<ReturnType<typeof getArchivesSchedulesUid>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!uid,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Delete the specified archived workflows.
 * @summary Delete the specified archived workflows.
 */
export const deleteArchivesWorkflows = (params: DeleteArchivesWorkflowsParams) => {
  return customInstance<UtilsResponse>({ url: `/archives/workflows`, method: 'delete', params })
}

export type DeleteArchivesWorkflowsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteArchivesWorkflows>>>

export type DeleteArchivesWorkflowsMutationError = UtilsAPIError

export const useDeleteArchivesWorkflows = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteArchivesWorkflows>>,
    TError,
    { params: DeleteArchivesWorkflowsParams },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteArchivesWorkflows>>,
    { params: DeleteArchivesWorkflowsParams }
  > = (props) => {
    const { params } = props ?? {}

    return deleteArchivesWorkflows(params)
  }

  return useMutation<
    Awaited<ReturnType<typeof deleteArchivesWorkflows>>,
    TError,
    { params: DeleteArchivesWorkflowsParams },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Get archived workflow.
 * @summary Get archived workflow.
 */
export const getArchivesWorkflows = (params?: GetArchivesWorkflowsParams, signal?: AbortSignal) => {
  return customInstance<TypesArchive[]>({ url: `/archives/workflows`, method: 'get', params, signal })
}

export const getGetArchivesWorkflowsQueryKey = (params?: GetArchivesWorkflowsParams) => [
  `/archives/workflows`,
  ...(params ? [params] : []),
]

export type GetArchivesWorkflowsQueryResult = NonNullable<Awaited<ReturnType<typeof getArchivesWorkflows>>>
export type GetArchivesWorkflowsQueryError = UtilsAPIError

export const useGetArchivesWorkflows = <
  TData = Awaited<ReturnType<typeof getArchivesWorkflows>>,
  TError = UtilsAPIError
>(
  params?: GetArchivesWorkflowsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getArchivesWorkflows>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetArchivesWorkflowsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getArchivesWorkflows>>> = ({ signal }) =>
    getArchivesWorkflows(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getArchivesWorkflows>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Delete the specified archived workflow.
 * @summary Delete the specified archived workflow.
 */
export const deleteArchivesWorkflowsUid = (uid: string) => {
  return customInstance<UtilsResponse>({ url: `/archives/workflows/${uid}`, method: 'delete' })
}

export type DeleteArchivesWorkflowsUidMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteArchivesWorkflowsUid>>
>

export type DeleteArchivesWorkflowsUidMutationError = UtilsAPIError

export const useDeleteArchivesWorkflowsUid = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteArchivesWorkflowsUid>>,
    TError,
    { uid: string },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteArchivesWorkflowsUid>>, { uid: string }> = (
    props
  ) => {
    const { uid } = props ?? {}

    return deleteArchivesWorkflowsUid(uid)
  }

  return useMutation<Awaited<ReturnType<typeof deleteArchivesWorkflowsUid>>, TError, { uid: string }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Get the detail of an archived workflow.
 * @summary Get the detail of an archived workflow.
 */
export const getArchivesWorkflowsUid = (uid: string, signal?: AbortSignal) => {
  return customInstance<TypesArchiveDetail>({ url: `/archives/workflows/${uid}`, method: 'get', signal })
}

export const getGetArchivesWorkflowsUidQueryKey = (uid: string) => [`/archives/workflows/${uid}`]

export type GetArchivesWorkflowsUidQueryResult = NonNullable<Awaited<ReturnType<typeof getArchivesWorkflowsUid>>>
export type GetArchivesWorkflowsUidQueryError = UtilsAPIError

export const useGetArchivesWorkflowsUid = <
  TData = Awaited<ReturnType<typeof getArchivesWorkflowsUid>>,
  TError = UtilsAPIError
>(
  uid: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getArchivesWorkflowsUid>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetArchivesWorkflowsUidQueryKey(uid)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getArchivesWorkflowsUid>>> = ({ signal }) =>
    getArchivesWorkflowsUid(uid, signal)

  const query = useQuery<Awaited<ReturnType<typeof getArchivesWorkflowsUid>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!uid,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Get the annotations of the pods in the specified namespace from Kubernetes cluster.
 * @summary Get the annotations of the pods in the specified namespace from Kubernetes cluster.
 */
export const getCommonAnnotations = (params: GetCommonAnnotationsParams, signal?: AbortSignal) => {
  return customInstance<UtilsMapStringSliceResponse>({ url: `/common/annotations`, method: 'get', params, signal })
}

export const getGetCommonAnnotationsQueryKey = (params: GetCommonAnnotationsParams) => [
  `/common/annotations`,
  ...(params ? [params] : []),
]

export type GetCommonAnnotationsQueryResult = NonNullable<Awaited<ReturnType<typeof getCommonAnnotations>>>
export type GetCommonAnnotationsQueryError = UtilsAPIError

export const useGetCommonAnnotations = <
  TData = Awaited<ReturnType<typeof getCommonAnnotations>>,
  TError = UtilsAPIError
>(
  params: GetCommonAnnotationsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getCommonAnnotations>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommonAnnotationsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommonAnnotations>>> = ({ signal }) =>
    getCommonAnnotations(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getCommonAnnotations>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Get all namespaces which could inject chaos(explosion scope) from Kubernetes cluster.
 * @summary Get all namespaces which could inject chaos(explosion scope) from Kubernetes cluster.
 */
export const getCommonChaosAvailableNamespaces = (signal?: AbortSignal) => {
  return customInstance<string[]>({ url: `/common/chaos-available-namespaces`, method: 'get', signal })
}

export const getGetCommonChaosAvailableNamespacesQueryKey = () => [`/common/chaos-available-namespaces`]

export type GetCommonChaosAvailableNamespacesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCommonChaosAvailableNamespaces>>
>
export type GetCommonChaosAvailableNamespacesQueryError = UtilsAPIError

export const useGetCommonChaosAvailableNamespaces = <
  TData = Awaited<ReturnType<typeof getCommonChaosAvailableNamespaces>>,
  TError = UtilsAPIError
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCommonChaosAvailableNamespaces>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommonChaosAvailableNamespacesQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommonChaosAvailableNamespaces>>> = ({ signal }) =>
    getCommonChaosAvailableNamespaces(signal)

  const query = useQuery<Awaited<ReturnType<typeof getCommonChaosAvailableNamespaces>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Get the config of Dashboard.
 * @summary Get the config of Dashboard.
 */
export const getCommonConfig = (signal?: AbortSignal) => {
  return customInstance<ConfigChaosDashboardConfig>({ url: `/common/config`, method: 'get', signal })
}

export const getGetCommonConfigQueryKey = () => [`/common/config`]

export type GetCommonConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getCommonConfig>>>
export type GetCommonConfigQueryError = UtilsAPIError

export const useGetCommonConfig = <
  TData = Awaited<ReturnType<typeof getCommonConfig>>,
  TError = UtilsAPIError
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCommonConfig>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommonConfigQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommonConfig>>> = ({ signal }) => getCommonConfig(signal)

  const query = useQuery<Awaited<ReturnType<typeof getCommonConfig>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Get all chaos kinds from Kubernetes cluster.
 * @summary Get all chaos kinds from Kubernetes cluster.
 */
export const getCommonKinds = (signal?: AbortSignal) => {
  return customInstance<string[]>({ url: `/common/kinds`, method: 'get', signal })
}

export const getGetCommonKindsQueryKey = () => [`/common/kinds`]

export type GetCommonKindsQueryResult = NonNullable<Awaited<ReturnType<typeof getCommonKinds>>>
export type GetCommonKindsQueryError = UtilsAPIError

export const useGetCommonKinds = <
  TData = Awaited<ReturnType<typeof getCommonKinds>>,
  TError = UtilsAPIError
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCommonKinds>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommonKindsQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommonKinds>>> = ({ signal }) => getCommonKinds(signal)

  const query = useQuery<Awaited<ReturnType<typeof getCommonKinds>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Get the labels of the pods in the specified namespace from Kubernetes cluster.
 * @summary Get the labels of the pods in the specified namespace from Kubernetes cluster.
 */
export const getCommonLabels = (params: GetCommonLabelsParams, signal?: AbortSignal) => {
  return customInstance<UtilsMapStringSliceResponse>({ url: `/common/labels`, method: 'get', params, signal })
}

export const getGetCommonLabelsQueryKey = (params: GetCommonLabelsParams) => [
  `/common/labels`,
  ...(params ? [params] : []),
]

export type GetCommonLabelsQueryResult = NonNullable<Awaited<ReturnType<typeof getCommonLabels>>>
export type GetCommonLabelsQueryError = UtilsAPIError

export const useGetCommonLabels = <TData = Awaited<ReturnType<typeof getCommonLabels>>, TError = UtilsAPIError>(
  params: GetCommonLabelsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getCommonLabels>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommonLabelsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommonLabels>>> = ({ signal }) =>
    getCommonLabels(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getCommonLabels>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Get all from Kubernetes cluster.
 * @deprecated
 * @summary Get all namespaces from Kubernetes cluster.
 */
export const getCommonNamespaces = (signal?: AbortSignal) => {
  return customInstance<string[]>({ url: `/common/namespaces`, method: 'get', signal })
}

export const getGetCommonNamespacesQueryKey = () => [`/common/namespaces`]

export type GetCommonNamespacesQueryResult = NonNullable<Awaited<ReturnType<typeof getCommonNamespaces>>>
export type GetCommonNamespacesQueryError = UtilsAPIError

export const useGetCommonNamespaces = <
  TData = Awaited<ReturnType<typeof getCommonNamespaces>>,
  TError = UtilsAPIError
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getCommonNamespaces>>, TError, TData>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommonNamespacesQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommonNamespaces>>> = ({ signal }) =>
    getCommonNamespaces(signal)

  const query = useQuery<Awaited<ReturnType<typeof getCommonNamespaces>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Get the annotations of the physicalMachines in the specified namespace from Kubernetes cluster.
 * @summary Get the annotations of the physicalMachines in the specified namespace from Kubernetes cluster.
 */
export const getCommonPhysicalmachineAnnotations = (
  params: GetCommonPhysicalmachineAnnotationsParams,
  signal?: AbortSignal
) => {
  return customInstance<UtilsMapStringSliceResponse>({
    url: `/common/physicalmachine-annotations`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetCommonPhysicalmachineAnnotationsQueryKey = (params: GetCommonPhysicalmachineAnnotationsParams) => [
  `/common/physicalmachine-annotations`,
  ...(params ? [params] : []),
]

export type GetCommonPhysicalmachineAnnotationsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCommonPhysicalmachineAnnotations>>
>
export type GetCommonPhysicalmachineAnnotationsQueryError = UtilsAPIError

export const useGetCommonPhysicalmachineAnnotations = <
  TData = Awaited<ReturnType<typeof getCommonPhysicalmachineAnnotations>>,
  TError = UtilsAPIError
>(
  params: GetCommonPhysicalmachineAnnotationsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getCommonPhysicalmachineAnnotations>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommonPhysicalmachineAnnotationsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommonPhysicalmachineAnnotations>>> = ({ signal }) =>
    getCommonPhysicalmachineAnnotations(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getCommonPhysicalmachineAnnotations>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Get the labels of the physicalMachines in the specified namespace from Kubernetes cluster.
 * @summary Get the labels of the physicalMachines in the specified namespace from Kubernetes cluster.
 */
export const getCommonPhysicalmachineLabels = (params: GetCommonPhysicalmachineLabelsParams, signal?: AbortSignal) => {
  return customInstance<UtilsMapStringSliceResponse>({
    url: `/common/physicalmachine-labels`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetCommonPhysicalmachineLabelsQueryKey = (params: GetCommonPhysicalmachineLabelsParams) => [
  `/common/physicalmachine-labels`,
  ...(params ? [params] : []),
]

export type GetCommonPhysicalmachineLabelsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCommonPhysicalmachineLabels>>
>
export type GetCommonPhysicalmachineLabelsQueryError = UtilsAPIError

export const useGetCommonPhysicalmachineLabels = <
  TData = Awaited<ReturnType<typeof getCommonPhysicalmachineLabels>>,
  TError = UtilsAPIError
>(
  params: GetCommonPhysicalmachineLabelsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getCommonPhysicalmachineLabels>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommonPhysicalmachineLabelsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommonPhysicalmachineLabels>>> = ({ signal }) =>
    getCommonPhysicalmachineLabels(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getCommonPhysicalmachineLabels>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Get physicalMachines from Kubernetes cluster.
 * @summary Get physicalMachines from Kubernetes cluster.
 */
export const postCommonPhysicalmachines = (
  v1alpha1PhysicalMachineSelectorSpec: V1alpha1PhysicalMachineSelectorSpec
) => {
  return customInstance<TypesPhysicalMachine[]>({
    url: `/common/physicalmachines`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: v1alpha1PhysicalMachineSelectorSpec,
  })
}

export type PostCommonPhysicalmachinesMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCommonPhysicalmachines>>
>
export type PostCommonPhysicalmachinesMutationBody = V1alpha1PhysicalMachineSelectorSpec
export type PostCommonPhysicalmachinesMutationError = UtilsAPIError

export const usePostCommonPhysicalmachines = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCommonPhysicalmachines>>,
    TError,
    { data: V1alpha1PhysicalMachineSelectorSpec },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCommonPhysicalmachines>>,
    { data: V1alpha1PhysicalMachineSelectorSpec }
  > = (props) => {
    const { data } = props ?? {}

    return postCommonPhysicalmachines(data)
  }

  return useMutation<
    Awaited<ReturnType<typeof postCommonPhysicalmachines>>,
    TError,
    { data: V1alpha1PhysicalMachineSelectorSpec },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Get pods from Kubernetes cluster.
 * @summary Get pods from Kubernetes cluster.
 */
export const postCommonPods = (v1alpha1PodSelectorSpec: V1alpha1PodSelectorSpec) => {
  return customInstance<TypesPod[]>({
    url: `/common/pods`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: v1alpha1PodSelectorSpec,
  })
}

export type PostCommonPodsMutationResult = NonNullable<Awaited<ReturnType<typeof postCommonPods>>>
export type PostCommonPodsMutationBody = V1alpha1PodSelectorSpec
export type PostCommonPodsMutationError = UtilsAPIError

export const usePostCommonPods = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCommonPods>>,
    TError,
    { data: V1alpha1PodSelectorSpec },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postCommonPods>>, { data: V1alpha1PodSelectorSpec }> = (
    props
  ) => {
    const { data } = props ?? {}

    return postCommonPods(data)
  }

  return useMutation<Awaited<ReturnType<typeof postCommonPods>>, TError, { data: V1alpha1PodSelectorSpec }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Get the rbac config according to the user's choice.
 * @summary Get the rbac config according to the user's choice.
 */
export const getCommonRbacConfig = (params?: GetCommonRbacConfigParams, signal?: AbortSignal) => {
  return customInstance<GetCommonRbacConfig200>({ url: `/common/rbac-config`, method: 'get', params, signal })
}

export const getGetCommonRbacConfigQueryKey = (params?: GetCommonRbacConfigParams) => [
  `/common/rbac-config`,
  ...(params ? [params] : []),
]

export type GetCommonRbacConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getCommonRbacConfig>>>
export type GetCommonRbacConfigQueryError = UtilsAPIError

export const useGetCommonRbacConfig = <TData = Awaited<ReturnType<typeof getCommonRbacConfig>>, TError = UtilsAPIError>(
  params?: GetCommonRbacConfigParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getCommonRbacConfig>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommonRbacConfigQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommonRbacConfig>>> = ({ signal }) =>
    getCommonRbacConfig(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getCommonRbacConfig>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Get events from db.
 * @summary list events.
 */
export const getEvents = (params?: GetEventsParams, signal?: AbortSignal) => {
  return customInstance<CoreEvent[]>({ url: `/events`, method: 'get', params, signal })
}

export const getGetEventsQueryKey = (params?: GetEventsParams) => [`/events`, ...(params ? [params] : [])]

export type GetEventsQueryResult = NonNullable<Awaited<ReturnType<typeof getEvents>>>
export type GetEventsQueryError = UtilsAPIError

export const useGetEvents = <TData = Awaited<ReturnType<typeof getEvents>>, TError = UtilsAPIError>(
  params?: GetEventsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getEvents>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetEventsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEvents>>> = ({ signal }) => getEvents(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getEvents>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Get the event from db by ID.
 * @summary Get an event.
 */
export const getEventsId = (id: number, signal?: AbortSignal) => {
  return customInstance<CoreEvent>({ url: `/events/${id}`, method: 'get', signal })
}

export const getGetEventsIdQueryKey = (id: number) => [`/events/${id}`]

export type GetEventsIdQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsId>>>
export type GetEventsIdQueryError = UtilsAPIError

export const useGetEventsId = <TData = Awaited<ReturnType<typeof getEventsId>>, TError = UtilsAPIError>(
  id: number,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getEventsId>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetEventsIdQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsId>>> = ({ signal }) => getEventsId(id, signal)

  const query = useQuery<Awaited<ReturnType<typeof getEventsId>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!id,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * list all events for Workflow and related WorkflowNode.
 * @summary cascadeFetchEventsForWorkflow list all events for Workflow and related WorkflowNode.
 */
export const getEventsWorkflowUid = (uid: string, params?: GetEventsWorkflowUidParams, signal?: AbortSignal) => {
  return customInstance<CoreEvent[]>({ url: `/events/workflow/${uid}`, method: 'get', params, signal })
}

export const getGetEventsWorkflowUidQueryKey = (uid: string, params?: GetEventsWorkflowUidParams) => [
  `/events/workflow/${uid}`,
  ...(params ? [params] : []),
]

export type GetEventsWorkflowUidQueryResult = NonNullable<Awaited<ReturnType<typeof getEventsWorkflowUid>>>
export type GetEventsWorkflowUidQueryError = UtilsAPIError

export const useGetEventsWorkflowUid = <
  TData = Awaited<ReturnType<typeof getEventsWorkflowUid>>,
  TError = UtilsAPIError
>(
  uid: string,
  params?: GetEventsWorkflowUidParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getEventsWorkflowUid>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetEventsWorkflowUidQueryKey(uid, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventsWorkflowUid>>> = ({ signal }) =>
    getEventsWorkflowUid(uid, params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getEventsWorkflowUid>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!uid,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Batch delete chaos experiments by uids.
 * @summary Batch delete chaos experiments.
 */
export const deleteExperiments = (params: DeleteExperimentsParams) => {
  return customInstance<UtilsResponse>({ url: `/experiments`, method: 'delete', params })
}

export type DeleteExperimentsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteExperiments>>>

export type DeleteExperimentsMutationError = UtilsAPIError

export const useDeleteExperiments = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteExperiments>>,
    TError,
    { params: DeleteExperimentsParams },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteExperiments>>,
    { params: DeleteExperimentsParams }
  > = (props) => {
    const { params } = props ?? {}

    return deleteExperiments(params)
  }

  return useMutation<
    Awaited<ReturnType<typeof deleteExperiments>>,
    TError,
    { params: DeleteExperimentsParams },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Get chaos experiments from k8s clusters in real time.
 * @summary List chaos experiments.
 */
export const getExperiments = (params?: GetExperimentsParams, signal?: AbortSignal) => {
  return customInstance<TypesExperiment[]>({ url: `/experiments`, method: 'get', params, signal })
}

export const getGetExperimentsQueryKey = (params?: GetExperimentsParams) => [
  `/experiments`,
  ...(params ? [params] : []),
]

export type GetExperimentsQueryResult = NonNullable<Awaited<ReturnType<typeof getExperiments>>>
export type GetExperimentsQueryError = UtilsAPIError

export const useGetExperiments = <TData = Awaited<ReturnType<typeof getExperiments>>, TError = UtilsAPIError>(
  params?: GetExperimentsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getExperiments>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetExperimentsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getExperiments>>> = ({ signal }) =>
    getExperiments(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getExperiments>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Pass a JSON object to create a new chaos experiment. The schema for JSON is the same as the YAML schema for the Kubernetes object.
 * @summary Create a new chaos experiment.
 */
export const postExperiments = (postExperimentsBody: PostExperimentsBody) => {
  return customInstance<PostExperiments200>({
    url: `/experiments`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: postExperimentsBody,
  })
}

export type PostExperimentsMutationResult = NonNullable<Awaited<ReturnType<typeof postExperiments>>>
export type PostExperimentsMutationBody = PostExperimentsBody
export type PostExperimentsMutationError = UtilsAPIError

export const usePostExperiments = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postExperiments>>,
    TError,
    { data: PostExperimentsBody },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postExperiments>>, { data: PostExperimentsBody }> = (
    props
  ) => {
    const { data } = props ?? {}

    return postExperiments(data)
  }

  return useMutation<Awaited<ReturnType<typeof postExperiments>>, TError, { data: PostExperimentsBody }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Delete the chaos experiment by uid.
 * @summary Delete a chaos experiment.
 */
export const deleteExperimentsUid = (uid: string, params?: DeleteExperimentsUidParams) => {
  return customInstance<UtilsResponse>({ url: `/experiments/${uid}`, method: 'delete', params })
}

export type DeleteExperimentsUidMutationResult = NonNullable<Awaited<ReturnType<typeof deleteExperimentsUid>>>

export type DeleteExperimentsUidMutationError = UtilsAPIError

export const useDeleteExperimentsUid = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteExperimentsUid>>,
    TError,
    { uid: string; params?: DeleteExperimentsUidParams },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteExperimentsUid>>,
    { uid: string; params?: DeleteExperimentsUidParams }
  > = (props) => {
    const { uid, params } = props ?? {}

    return deleteExperimentsUid(uid, params)
  }

  return useMutation<
    Awaited<ReturnType<typeof deleteExperimentsUid>>,
    TError,
    { uid: string; params?: DeleteExperimentsUidParams },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Get the chaos experiment's detail by uid.
 * @summary Get a chaos experiment.
 */
export const getExperimentsUid = (uid: string, signal?: AbortSignal) => {
  return customInstance<TypesExperimentDetail>({ url: `/experiments/${uid}`, method: 'get', signal })
}

export const getGetExperimentsUidQueryKey = (uid: string) => [`/experiments/${uid}`]

export type GetExperimentsUidQueryResult = NonNullable<Awaited<ReturnType<typeof getExperimentsUid>>>
export type GetExperimentsUidQueryError = UtilsAPIError

export const useGetExperimentsUid = <TData = Awaited<ReturnType<typeof getExperimentsUid>>, TError = UtilsAPIError>(
  uid: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getExperimentsUid>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetExperimentsUidQueryKey(uid)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getExperimentsUid>>> = ({ signal }) =>
    getExperimentsUid(uid, signal)

  const query = useQuery<Awaited<ReturnType<typeof getExperimentsUid>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!uid,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Pause a chaos experiment.
 * @summary Pause a chaos experiment.
 */
export const putExperimentsPauseUid = (uid: string) => {
  return customInstance<UtilsResponse>({ url: `/experiments/pause/${uid}`, method: 'put' })
}

export type PutExperimentsPauseUidMutationResult = NonNullable<Awaited<ReturnType<typeof putExperimentsPauseUid>>>

export type PutExperimentsPauseUidMutationError = UtilsAPIError

export const usePutExperimentsPauseUid = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putExperimentsPauseUid>>, TError, { uid: string }, TContext>
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putExperimentsPauseUid>>, { uid: string }> = (props) => {
    const { uid } = props ?? {}

    return putExperimentsPauseUid(uid)
  }

  return useMutation<Awaited<ReturnType<typeof putExperimentsPauseUid>>, TError, { uid: string }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Start a chaos experiment.
 * @summary Start a chaos experiment.
 */
export const putExperimentsStartUid = (uid: string) => {
  return customInstance<UtilsResponse>({ url: `/experiments/start/${uid}`, method: 'put' })
}

export type PutExperimentsStartUidMutationResult = NonNullable<Awaited<ReturnType<typeof putExperimentsStartUid>>>

export type PutExperimentsStartUidMutationError = UtilsAPIError

export const usePutExperimentsStartUid = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putExperimentsStartUid>>, TError, { uid: string }, TContext>
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putExperimentsStartUid>>, { uid: string }> = (props) => {
    const { uid } = props ?? {}

    return putExperimentsStartUid(uid)
  }

  return useMutation<Awaited<ReturnType<typeof putExperimentsStartUid>>, TError, { uid: string }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Get the status of all experiments.
 * @summary Get the status of all experiments.
 */
export const getExperimentsState = (params?: GetExperimentsStateParams, signal?: AbortSignal) => {
  return customInstance<StatusAllChaosStatus>({ url: `/experiments/state`, method: 'get', params, signal })
}

export const getGetExperimentsStateQueryKey = (params?: GetExperimentsStateParams) => [
  `/experiments/state`,
  ...(params ? [params] : []),
]

export type GetExperimentsStateQueryResult = NonNullable<Awaited<ReturnType<typeof getExperimentsState>>>
export type GetExperimentsStateQueryError = UtilsAPIError

export const useGetExperimentsState = <TData = Awaited<ReturnType<typeof getExperimentsState>>, TError = UtilsAPIError>(
  params?: GetExperimentsStateParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getExperimentsState>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetExperimentsStateQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getExperimentsState>>> = ({ signal }) =>
    getExperimentsState(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getExperimentsState>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Batch delete schedules by uids.
 * @summary Batch delete schedules.
 */
export const deleteSchedules = (params: DeleteSchedulesParams) => {
  return customInstance<UtilsResponse>({ url: `/schedules`, method: 'delete', params })
}

export type DeleteSchedulesMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSchedules>>>

export type DeleteSchedulesMutationError = UtilsAPIError

export const useDeleteSchedules = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteSchedules>>,
    TError,
    { params: DeleteSchedulesParams },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSchedules>>, { params: DeleteSchedulesParams }> = (
    props
  ) => {
    const { params } = props ?? {}

    return deleteSchedules(params)
  }

  return useMutation<Awaited<ReturnType<typeof deleteSchedules>>, TError, { params: DeleteSchedulesParams }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Get chaos schedules from k8s cluster in real time.
 * @summary List chaos schedules.
 */
export const getSchedules = (params?: GetSchedulesParams, signal?: AbortSignal) => {
  return customInstance<TypesSchedule[]>({ url: `/schedules`, method: 'get', params, signal })
}

export const getGetSchedulesQueryKey = (params?: GetSchedulesParams) => [`/schedules`, ...(params ? [params] : [])]

export type GetSchedulesQueryResult = NonNullable<Awaited<ReturnType<typeof getSchedules>>>
export type GetSchedulesQueryError = UtilsAPIError

export const useGetSchedules = <TData = Awaited<ReturnType<typeof getSchedules>>, TError = UtilsAPIError>(
  params?: GetSchedulesParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getSchedules>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSchedulesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSchedules>>> = ({ signal }) => getSchedules(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getSchedules>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Pass a JSON object to create a new schedule. The schema for JSON is the same as the YAML schema for the Kubernetes object.
 * @summary Create a new schedule.
 */
export const postSchedules = (v1alpha1Schedule: V1alpha1Schedule) => {
  return customInstance<V1alpha1Schedule>({
    url: `/schedules`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: v1alpha1Schedule,
  })
}

export type PostSchedulesMutationResult = NonNullable<Awaited<ReturnType<typeof postSchedules>>>
export type PostSchedulesMutationBody = V1alpha1Schedule
export type PostSchedulesMutationError = UtilsAPIError

export const usePostSchedules = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postSchedules>>, TError, { data: V1alpha1Schedule }, TContext>
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postSchedules>>, { data: V1alpha1Schedule }> = (
    props
  ) => {
    const { data } = props ?? {}

    return postSchedules(data)
  }

  return useMutation<Awaited<ReturnType<typeof postSchedules>>, TError, { data: V1alpha1Schedule }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Delete the schedule by uid.
 * @summary Delete a schedule.
 */
export const deleteSchedulesUid = (uid: string) => {
  return customInstance<UtilsResponse>({ url: `/schedules/${uid}`, method: 'delete' })
}

export type DeleteSchedulesUidMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSchedulesUid>>>

export type DeleteSchedulesUidMutationError = UtilsAPIError

export const useDeleteSchedulesUid = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteSchedulesUid>>, TError, { uid: string }, TContext>
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSchedulesUid>>, { uid: string }> = (props) => {
    const { uid } = props ?? {}

    return deleteSchedulesUid(uid)
  }

  return useMutation<Awaited<ReturnType<typeof deleteSchedulesUid>>, TError, { uid: string }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Get the schedule's detail by uid.
 * @summary Get a schedule.
 */
export const getSchedulesUid = (uid: string, signal?: AbortSignal) => {
  return customInstance<TypesScheduleDetail>({ url: `/schedules/${uid}`, method: 'get', signal })
}

export const getGetSchedulesUidQueryKey = (uid: string) => [`/schedules/${uid}`]

export type GetSchedulesUidQueryResult = NonNullable<Awaited<ReturnType<typeof getSchedulesUid>>>
export type GetSchedulesUidQueryError = UtilsAPIError

export const useGetSchedulesUid = <TData = Awaited<ReturnType<typeof getSchedulesUid>>, TError = UtilsAPIError>(
  uid: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getSchedulesUid>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSchedulesUidQueryKey(uid)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSchedulesUid>>> = ({ signal }) =>
    getSchedulesUid(uid, signal)

  const query = useQuery<Awaited<ReturnType<typeof getSchedulesUid>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!uid,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Pause a schedule.
 * @summary Pause a schedule.
 */
export const putSchedulesPauseUid = (uid: string) => {
  return customInstance<UtilsResponse>({ url: `/schedules/pause/${uid}`, method: 'put' })
}

export type PutSchedulesPauseUidMutationResult = NonNullable<Awaited<ReturnType<typeof putSchedulesPauseUid>>>

export type PutSchedulesPauseUidMutationError = UtilsAPIError

export const usePutSchedulesPauseUid = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putSchedulesPauseUid>>, TError, { uid: string }, TContext>
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putSchedulesPauseUid>>, { uid: string }> = (props) => {
    const { uid } = props ?? {}

    return putSchedulesPauseUid(uid)
  }

  return useMutation<Awaited<ReturnType<typeof putSchedulesPauseUid>>, TError, { uid: string }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Start a schedule.
 * @summary Start a schedule.
 */
export const putSchedulesStartUid = (uid: string) => {
  return customInstance<UtilsResponse>({ url: `/schedules/start/${uid}`, method: 'put' })
}

export type PutSchedulesStartUidMutationResult = NonNullable<Awaited<ReturnType<typeof putSchedulesStartUid>>>

export type PutSchedulesStartUidMutationError = UtilsAPIError

export const usePutSchedulesStartUid = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putSchedulesStartUid>>, TError, { uid: string }, TContext>
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putSchedulesStartUid>>, { uid: string }> = (props) => {
    const { uid } = props ?? {}

    return putSchedulesStartUid(uid)
  }

  return useMutation<Awaited<ReturnType<typeof putSchedulesStartUid>>, TError, { uid: string }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Get status check templates from k8s cluster in real time.
 * @summary List status check templates.
 */
export const getTemplatesStatuschecks = (params?: GetTemplatesStatuschecksParams, signal?: AbortSignal) => {
  return customInstance<TypesStatusCheckTemplateBase[]>({
    url: `/templates/statuschecks`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetTemplatesStatuschecksQueryKey = (params?: GetTemplatesStatuschecksParams) => [
  `/templates/statuschecks`,
  ...(params ? [params] : []),
]

export type GetTemplatesStatuschecksQueryResult = NonNullable<Awaited<ReturnType<typeof getTemplatesStatuschecks>>>
export type GetTemplatesStatuschecksQueryError = UtilsAPIError

export const useGetTemplatesStatuschecks = <
  TData = Awaited<ReturnType<typeof getTemplatesStatuschecks>>,
  TError = UtilsAPIError
>(
  params?: GetTemplatesStatuschecksParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getTemplatesStatuschecks>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTemplatesStatuschecksQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTemplatesStatuschecks>>> = ({ signal }) =>
    getTemplatesStatuschecks(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getTemplatesStatuschecks>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Pass a JSON object to create a new status check template.
 * @summary Create a new status check template.
 */
export const postTemplatesStatuschecks = (typesStatusCheckTemplate: TypesStatusCheckTemplate) => {
  return customInstance<TypesStatusCheckTemplate>({
    url: `/templates/statuschecks`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: typesStatusCheckTemplate,
  })
}

export type PostTemplatesStatuschecksMutationResult = NonNullable<Awaited<ReturnType<typeof postTemplatesStatuschecks>>>
export type PostTemplatesStatuschecksMutationBody = TypesStatusCheckTemplate
export type PostTemplatesStatuschecksMutationError = UtilsAPIError

export const usePostTemplatesStatuschecks = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postTemplatesStatuschecks>>,
    TError,
    { data: TypesStatusCheckTemplate },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postTemplatesStatuschecks>>,
    { data: TypesStatusCheckTemplate }
  > = (props) => {
    const { data } = props ?? {}

    return postTemplatesStatuschecks(data)
  }

  return useMutation<
    Awaited<ReturnType<typeof postTemplatesStatuschecks>>,
    TError,
    { data: TypesStatusCheckTemplate },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Delete the status check template by namespaced name.
 * @summary Delete a status check template.
 */
export const deleteTemplatesStatuschecksStatuscheck = (params: DeleteTemplatesStatuschecksStatuscheckParams) => {
  return customInstance<UtilsResponse>({ url: `/templates/statuschecks/statuscheck`, method: 'delete', params })
}

export type DeleteTemplatesStatuschecksStatuscheckMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteTemplatesStatuschecksStatuscheck>>
>

export type DeleteTemplatesStatuschecksStatuscheckMutationError = UtilsAPIError

export const useDeleteTemplatesStatuschecksStatuscheck = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteTemplatesStatuschecksStatuscheck>>,
    TError,
    { params: DeleteTemplatesStatuschecksStatuscheckParams },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteTemplatesStatuschecksStatuscheck>>,
    { params: DeleteTemplatesStatuschecksStatuscheckParams }
  > = (props) => {
    const { params } = props ?? {}

    return deleteTemplatesStatuschecksStatuscheck(params)
  }

  return useMutation<
    Awaited<ReturnType<typeof deleteTemplatesStatuschecksStatuscheck>>,
    TError,
    { params: DeleteTemplatesStatuschecksStatuscheckParams },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Get the status check template's detail by namespaced name.
 * @summary Get a status check template.
 */
export const getTemplatesStatuschecksStatuscheck = (
  params: GetTemplatesStatuschecksStatuscheckParams,
  signal?: AbortSignal
) => {
  return customInstance<TypesStatusCheckTemplateDetail>({
    url: `/templates/statuschecks/statuscheck`,
    method: 'get',
    params,
    signal,
  })
}

export const getGetTemplatesStatuschecksStatuscheckQueryKey = (params: GetTemplatesStatuschecksStatuscheckParams) => [
  `/templates/statuschecks/statuscheck`,
  ...(params ? [params] : []),
]

export type GetTemplatesStatuschecksStatuscheckQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTemplatesStatuschecksStatuscheck>>
>
export type GetTemplatesStatuschecksStatuscheckQueryError = UtilsAPIError

export const useGetTemplatesStatuschecksStatuscheck = <
  TData = Awaited<ReturnType<typeof getTemplatesStatuschecksStatuscheck>>,
  TError = UtilsAPIError
>(
  params: GetTemplatesStatuschecksStatuscheckParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getTemplatesStatuschecksStatuscheck>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTemplatesStatuschecksStatuscheckQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTemplatesStatuschecksStatuscheck>>> = ({ signal }) =>
    getTemplatesStatuschecksStatuscheck(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getTemplatesStatuschecksStatuscheck>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Update a status check template by namespaced name.
 * @summary Update a status check template.
 */
export const putTemplatesStatuschecksStatuscheck = (typesStatusCheckTemplate: TypesStatusCheckTemplate) => {
  return customInstance<TypesStatusCheckTemplate>({
    url: `/templates/statuschecks/statuscheck`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: typesStatusCheckTemplate,
  })
}

export type PutTemplatesStatuschecksStatuscheckMutationResult = NonNullable<
  Awaited<ReturnType<typeof putTemplatesStatuschecksStatuscheck>>
>
export type PutTemplatesStatuschecksStatuscheckMutationBody = TypesStatusCheckTemplate
export type PutTemplatesStatuschecksStatuscheckMutationError = UtilsAPIError

export const usePutTemplatesStatuschecksStatuscheck = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putTemplatesStatuschecksStatuscheck>>,
    TError,
    { data: TypesStatusCheckTemplate },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putTemplatesStatuschecksStatuscheck>>,
    { data: TypesStatusCheckTemplate }
  > = (props) => {
    const { data } = props ?? {}

    return putTemplatesStatuschecksStatuscheck(data)
  }

  return useMutation<
    Awaited<ReturnType<typeof putTemplatesStatuschecksStatuscheck>>,
    TError,
    { data: TypesStatusCheckTemplate },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * List workflows from Kubernetes cluster.
 * @summary List workflows from Kubernetes cluster.
 */
export const getWorkflows = (params?: GetWorkflowsParams, signal?: AbortSignal) => {
  return customInstance<CoreWorkflowMeta[]>({ url: `/workflows`, method: 'get', params, signal })
}

export const getGetWorkflowsQueryKey = (params?: GetWorkflowsParams) => [`/workflows`, ...(params ? [params] : [])]

export type GetWorkflowsQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflows>>>
export type GetWorkflowsQueryError = UtilsAPIError

export const useGetWorkflows = <TData = Awaited<ReturnType<typeof getWorkflows>>, TError = UtilsAPIError>(
  params?: GetWorkflowsParams,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getWorkflows>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetWorkflowsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflows>>> = ({ signal }) => getWorkflows(params, signal)

  const query = useQuery<Awaited<ReturnType<typeof getWorkflows>>, TError, TData>({
    queryKey,
    queryFn,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Create a new workflow.
 * @summary Create a new workflow.
 */
export const postWorkflows = (v1alpha1WorkflowBody: V1alpha1WorkflowBody) => {
  return customInstance<CoreWorkflowDetail>({
    url: `/workflows`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: v1alpha1WorkflowBody,
  })
}

export type PostWorkflowsMutationResult = NonNullable<Awaited<ReturnType<typeof postWorkflows>>>
export type PostWorkflowsMutationBody = V1alpha1WorkflowBody
export type PostWorkflowsMutationError = UtilsAPIError

export const usePostWorkflows = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postWorkflows>>,
    TError,
    { data: V1alpha1WorkflowBody },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postWorkflows>>, { data: V1alpha1WorkflowBody }> = (
    props
  ) => {
    const { data } = props ?? {}

    return postWorkflows(data)
  }

  return useMutation<Awaited<ReturnType<typeof postWorkflows>>, TError, { data: V1alpha1WorkflowBody }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Delete the specified workflow.
 * @summary Delete the specified workflow.
 */
export const deleteWorkflowsUid = (uid: string) => {
  return customInstance<UtilsResponse>({ url: `/workflows/${uid}`, method: 'delete' })
}

export type DeleteWorkflowsUidMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWorkflowsUid>>>

export type DeleteWorkflowsUidMutationError = UtilsAPIError

export const useDeleteWorkflowsUid = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteWorkflowsUid>>, TError, { uid: string }, TContext>
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWorkflowsUid>>, { uid: string }> = (props) => {
    const { uid } = props ?? {}

    return deleteWorkflowsUid(uid)
  }

  return useMutation<Awaited<ReturnType<typeof deleteWorkflowsUid>>, TError, { uid: string }, TContext>(
    mutationFn,
    mutationOptions
  )
}

/**
 * Get detailed information about the specified workflow. If that object is not existed in kubernetes, it will only return ths persisted data in the database.
 * @summary Get detailed information about the specified workflow.
 */
export const getWorkflowsUid = (uid: string, signal?: AbortSignal) => {
  return customInstance<CoreWorkflowDetail>({ url: `/workflows/${uid}`, method: 'get', signal })
}

export const getGetWorkflowsUidQueryKey = (uid: string) => [`/workflows/${uid}`]

export type GetWorkflowsUidQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkflowsUid>>>
export type GetWorkflowsUidQueryError = UtilsAPIError

export const useGetWorkflowsUid = <TData = Awaited<ReturnType<typeof getWorkflowsUid>>, TError = UtilsAPIError>(
  uid: string,
  options?: { query?: UseQueryOptions<Awaited<ReturnType<typeof getWorkflowsUid>>, TError, TData> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetWorkflowsUidQueryKey(uid)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkflowsUid>>> = ({ signal }) =>
    getWorkflowsUid(uid, signal)

  const query = useQuery<Awaited<ReturnType<typeof getWorkflowsUid>>, TError, TData>({
    queryKey,
    queryFn,
    enabled: !!uid,
    retry: 1,
    retryDelay: 3000,
    ...queryOptions,
  }) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryKey

  return query
}

/**
 * Update a workflow.
 * @summary Update a workflow.
 */
export const putWorkflowsUid = (uid: string, v1alpha1WorkflowBody: V1alpha1WorkflowBody) => {
  return customInstance<CoreWorkflowDetail>({
    url: `/workflows/${uid}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: v1alpha1WorkflowBody,
  })
}

export type PutWorkflowsUidMutationResult = NonNullable<Awaited<ReturnType<typeof putWorkflowsUid>>>
export type PutWorkflowsUidMutationBody = V1alpha1WorkflowBody
export type PutWorkflowsUidMutationError = UtilsAPIError

export const usePutWorkflowsUid = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putWorkflowsUid>>,
    TError,
    { uid: string; data: V1alpha1WorkflowBody },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putWorkflowsUid>>,
    { uid: string; data: V1alpha1WorkflowBody }
  > = (props) => {
    const { uid, data } = props ?? {}

    return putWorkflowsUid(uid, data)
  }

  return useMutation<
    Awaited<ReturnType<typeof putWorkflowsUid>>,
    TError,
    { uid: string; data: V1alpha1WorkflowBody },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Parse the rendered task back to the original request
 * @summary Parse the rendered task back to the original request
 */
export const postWorkflowsParseTaskHttp = (v1alpha1TemplateBody: V1alpha1TemplateBody) => {
  return customInstance<CurlRequestForm>({
    url: `/workflows/parse-task/http`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: v1alpha1TemplateBody,
  })
}

export type PostWorkflowsParseTaskHttpMutationResult = NonNullable<
  Awaited<ReturnType<typeof postWorkflowsParseTaskHttp>>
>
export type PostWorkflowsParseTaskHttpMutationBody = V1alpha1TemplateBody
export type PostWorkflowsParseTaskHttpMutationError = UtilsAPIError

export const usePostWorkflowsParseTaskHttp = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postWorkflowsParseTaskHttp>>,
    TError,
    { data: V1alpha1TemplateBody },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postWorkflowsParseTaskHttp>>,
    { data: V1alpha1TemplateBody }
  > = (props) => {
    const { data } = props ?? {}

    return postWorkflowsParseTaskHttp(data)
  }

  return useMutation<
    Awaited<ReturnType<typeof postWorkflowsParseTaskHttp>>,
    TError,
    { data: V1alpha1TemplateBody },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Render a task which sends HTTP request
 * @summary Render a task which sends HTTP request
 */
export const postWorkflowsRenderTaskHttp = (curlRequestForm: CurlRequestForm) => {
  return customInstance<V1alpha1Template>({
    url: `/workflows/render-task/http`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: curlRequestForm,
  })
}

export type PostWorkflowsRenderTaskHttpMutationResult = NonNullable<
  Awaited<ReturnType<typeof postWorkflowsRenderTaskHttp>>
>
export type PostWorkflowsRenderTaskHttpMutationBody = CurlRequestForm
export type PostWorkflowsRenderTaskHttpMutationError = UtilsAPIError

export const usePostWorkflowsRenderTaskHttp = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postWorkflowsRenderTaskHttp>>,
    TError,
    { data: CurlRequestForm },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postWorkflowsRenderTaskHttp>>,
    { data: CurlRequestForm }
  > = (props) => {
    const { data } = props ?? {}

    return postWorkflowsRenderTaskHttp(data)
  }

  return useMutation<
    Awaited<ReturnType<typeof postWorkflowsRenderTaskHttp>>,
    TError,
    { data: CurlRequestForm },
    TContext
  >(mutationFn, mutationOptions)
}

/**
 * Validate the given template is a valid rendered HTTP Task
 * @summary Validate the given template is a valid rendered HTTP Task
 */
export const postWorkflowsValidateTaskHttp = (v1alpha1TemplateBody: V1alpha1TemplateBody) => {
  return customInstance<boolean>({
    url: `/workflows/validate-task/http`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: v1alpha1TemplateBody,
  })
}

export type PostWorkflowsValidateTaskHttpMutationResult = NonNullable<
  Awaited<ReturnType<typeof postWorkflowsValidateTaskHttp>>
>
export type PostWorkflowsValidateTaskHttpMutationBody = V1alpha1TemplateBody
export type PostWorkflowsValidateTaskHttpMutationError = UtilsAPIError

export const usePostWorkflowsValidateTaskHttp = <TError = UtilsAPIError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postWorkflowsValidateTaskHttp>>,
    TError,
    { data: V1alpha1TemplateBody },
    TContext
  >
}) => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postWorkflowsValidateTaskHttp>>,
    { data: V1alpha1TemplateBody }
  > = (props) => {
    const { data } = props ?? {}

    return postWorkflowsValidateTaskHttp(data)
  }

  return useMutation<
    Awaited<ReturnType<typeof postWorkflowsValidateTaskHttp>>,
    TError,
    { data: V1alpha1TemplateBody },
    TContext
  >(mutationFn, mutationOptions)
}
